import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// eslint-disable-next-line no-unused-vars
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line no-unused-vars
import { getUserData, getAbility, isUserLoggedIn } from '@/auth/utils'

import rootRoute from './root'
import franchiseRoute from './franchise'
import companyRoute from './company'
import eCommerceRoute from './services/eCommerce'
import authRoute from './auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      redirect: { name: 'auth-login' },
    },
    ...rootRoute,
    ...franchiseRoute,
    ...companyRoute,
    ...eCommerceRoute,
    ...authRoute,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // eslint-disable-next-line no-unused-vars
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()
  // eslint-disable-next-line no-unused-vars
  const ability = getAbility()

  // console.log(userData)
  // if (!canNavigate(to)) {
  //   if (to.meta.service !== 'Auth') {
  //     if (to.meta.service !== 'root') {
  //       // Redirect to login if not logged in
  //       if (to.meta.errorPage !== true) {
  //         // If logged in => not authorized
  //         return next({ name: 'error-404' })
  //       }
  //     }
  //   }
  // }

  // console.log(ability)
  // if (!canNavigate(to) === false) {
  //   if (userData != null) {
  //     if (userData.userType === 'root') {
  //       return next({ name: 'root-dashboard' })
  //     } if (userData.userType === 'franchise') {
  //       const franchiseData = localStorage.getItem('franchiseData') ? localStorage.getItem('franchiseData') : null
  //       if (franchiseData != null) {
  //         return next({ name: 'franchise-dashboard' })
  //       }
  //       return next({ name: 'select-franchise' })
  //     } if (userData.userType === 'company') {
  //       const companyData = localStorage.getItem('companyData') ? localStorage.getItem('companyData') : null
  //       if (companyData != null) {
  //         return next({ name: 'company-dashboard' })
  //       }
  //       return next({ name: 'select-company' })
  //     }
  //   }
  // }
  // Redirect to login if not logged in
  // if (isLoggedIn === null) return next({ name: 'auth-login' })
  // // Redirect if logged in

  if (to.meta.redirectIfLoggedIn === true) {
    // console.log('redirectIfLoggedIn', to.meta.redirectIfLoggedIn)
    // console.log('isLoggedIn', isLoggedIn)
    if (userData !== null) {
      if (userData.userType === 'root') {
        return next({ name: 'root-dashboard' })
      } if (userData.userType === 'franchise') {
        const franchiseData = localStorage.getItem('franchiseData') ? localStorage.getItem('franchiseData') : null
        if (franchiseData != null) {
          return next({ name: 'franchise-dashboard' })
        }
        return next({ name: 'select-franchise' })
      } if (userData.userType === 'company') {
        const companyData = localStorage.getItem('companyData') ? localStorage.getItem('companyData') : null
        if (companyData != null) {
          return next({ name: 'company-dashboard' })
        }
        return next({ name: 'select-company' })
      } if (userData.userType === 'staff') {
        const applicationData = localStorage.getItem('applicationData') ? localStorage.getItem('applicationData') : null
        if (applicationData != null) {
          if (JSON.parse(applicationData).appType === 'eCommerce') {
            return next({ name: 'e-commerce-dashboard' })
          }
        }
        return next({ name: 'auth-login' })
      }
    }
  } else if (isLoggedIn === null) {
    if (to.meta.errorPage !== true) {
      return next({ name: 'auth-login' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
