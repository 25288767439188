export default [
  {
    route: 'root-dashboard',
    title: 'root-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Root-dashboard',
    action: 'read',
  },
  {
    route: 'root-ability',
    title: 'root-ability',
    slug: 'ability',
    icon: 'TagIcon',
    i18n: 'Ability',
    resource: 'Root-ability',
    action: 'read',
  },
  {
    route: null,
    title: 'root-users',
    slug: 'users',
    icon: 'UsersIcon',
    i18n: 'Users',
    resource: 'Root-users',
    action: 'read',
    children: [
      {
        route: 'root-users-root',
        title: 'root-users-root',
        slug: 'userRoot',
        icon: 'BriefcaseIcon',
        i18n: 'Root',
        resource: 'Root-users-root',
        action: 'read',
      },
      {
        route: 'root-users-franchise',
        title: 'root-users-franchise',
        slug: 'userFranchise',
        icon: 'PackageIcon',
        i18n: 'Franchise',
        resource: 'Root-users-franchise',
        action: 'read',
      },
      {
        route: 'root-users-company',
        title: 'root-users-company',
        slug: 'userCompany',
        icon: 'PackageIcon',
        i18n: 'Company',
        resource: 'Root-users-company',
        action: 'read',
      },
      {
        route: 'root-users-staff',
        title: 'root-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'Root-users-staff',
        action: 'read',
      },
    ],
  },
  {
    route: 'root-franchise',
    title: 'root-franchise',
    slug: 'franchise',
    icon: 'ShoppingBagIcon',
    i18n: 'Franchise',
    resource: 'Root-franchise',
    action: 'read',
  },
  {
    route: 'root-company',
    title: 'root-company',
    slug: 'company',
    icon: 'HomeIcon',
    i18n: 'Company',
    resource: 'Root-company',
    action: 'read',
  },
  {
    route: 'root-services',
    title: 'root-services',
    slug: 'services',
    icon: 'ServerIcon',
    i18n: 'Services',
    // tag: '1',
    // tagVariant: 'light-warning',
    resource: 'Root-services',
    action: 'read',
  },
  {
    route: 'root-service-packages',
    title: 'root-service-packages',
    slug: 'service-packages',
    icon: 'BoxIcon',
    i18n: 'Service Package',
    // tag: '2',
    // tagVariant: 'light-success',
    resource: 'Root-service-packages',
    action: 'read',
  },
  {
    route: 'root-application',
    title: 'root-application',
    slug: 'application',
    icon: 'PocketIcon',
    i18n: 'Application',
    resource: 'Root-application',
    action: 'read',
  },
  {
    route: 'root-country',
    title: 'root-country',
    slug: 'country',
    icon: 'GlobeIcon',
    i18n: 'Country',
    resource: 'Root-country',
    action: 'read',
  },
  {
    route: 'root-geography',
    title: 'root-geography',
    slug: 'geography',
    icon: 'GlobeIcon',
    i18n: 'Geography',
    resource: 'Root-geography',
    action: 'read',
  },
  {
    route: 'root-bank',
    title: 'root-bank',
    slug: 'bank',
    icon: 'HomeIcon',
    i18n: 'Bank',
    resource: 'Root-bank',
    action: 'read',
  },
  {
    route: 'root-parcelDelivery',
    title: 'root-parcelDelivery',
    slug: 'parcelDelivery',
    icon: 'TruckIcon',
    i18n: 'Parcel Delivery',
    resource: 'Root-parcelDelivery',
    action: 'read',
  },
  {
    route: 'root-webview-whitelist',
    title: 'root-webview-whitelist',
    slug: 'webviewWhitelist',
    icon: 'GlobeIcon',
    i18n: 'Webview Whitelist',
    resource: 'Root-webview-whitelist',
    action: 'read',
  },
  {
    route: 'root-system',
    title: 'root-system',
    slug: 'system',
    icon: 'SettingsIcon',
    i18n: 'System',
    resource: 'Root-system',
    action: 'read',
  },
]
