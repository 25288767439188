export default [
  {
    path: '/root',
    meta: {
      service: 'root',
      resource: 'Root-dashboard',
      action: 'read',
    },
    redirect: { name: 'root-dashboard' },
  },
  {
    path: '/root/dashboard',
    name: 'root-dashboard',
    component: () => import('../views/root/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'root',
      resource: 'Root-dashboard',
      action: 'read',
    },
  },
  {
    path: '/root/ability',
    name: 'root-ability',
    component: () => import('../views/root/ability/Ability.vue'),
    meta: {
      pageTitle: 'Ability',
      service: 'root',
      resource: 'Root-ability',
      action: 'read',
    },
  },
  {
    path: '/root/ability/:id',
    name: 'root-ability-view',
    component: () => import('../views/root/ability/View.vue'),
    meta: {
      pageTitle: 'Ability View',
      service: 'root',
      resource: 'Root-ability-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ability',
          to: '/root/ability',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'root-ability',
    },
  },
  {
    path: '/root/ability/sort/:id',
    name: 'root-ability-sort',
    component: () => import('../views/root/ability/Sort.vue'),
    meta: {
      pageTitle: 'Ability Sort',
      service: 'root',
      resource: 'Root-ability-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ability',
          to: '/root/ability',
        },
        {
          text: 'Sort',
          active: true,
        },
      ],
      navActiveLink: 'root-ability',
    },
  },
  {
    path: '/root/users/root',
    name: 'root-users-root',
    component: () => import('../views/root/users/Users.vue'),
    meta: {
      pageTitle: 'Users Root',
      service: 'root',
      resource: 'Root-users-root',
      action: 'read',

    },
  },
  {
    path: '/root/users/franchise',
    name: 'root-users-franchise',
    component: () => import('../views/root/users/Users.vue'),
    meta: {
      pageTitle: 'Users Franchise',
      service: 'root',
      resource: 'Root-users-franchise',
      action: 'read',

    },
  },
  {
    path: '/root/users/company',
    name: 'root-users-company',
    component: () => import('../views/root/users/Users.vue'),
    meta: {
      pageTitle: 'Users Company',
      service: 'root',
      resource: 'Root-users-company',
      action: 'read',

    },
  },
  {
    path: '/root/users/staff',
    name: 'root-users-staff',
    component: () => import('../views/root/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'root',
      resource: 'Root-users-staff',
      action: 'read',

    },
  },
  {
    path: '/root/franchise',
    name: 'root-franchise',
    component: () => import('../views/root/franchise/Franchise.vue'),
    meta: {
      pageTitle: 'Franchise',
      service: 'root',
      resource: 'Root-franchise',
      action: 'read',

    },
  },
  {
    path: '/root/company',
    name: 'root-company',
    component: () => import('../views/root/company/Company.vue'),
    meta: {
      pageTitle: 'Company',
      service: 'root',
      resource: 'Root-company',
      action: 'read',
    },
  },
  {
    path: '/root/services',
    name: 'root-services',
    component: () => import('../views/root/services/Services.vue'),
    meta: {
      pageTitle: 'Services',
      service: 'root',
      resource: 'Root-services',
      action: 'read',
    },
  },
  {
    path: '/root/service/:id',
    name: 'root-service-config',
    component: () => import('../views/root/services/config/i18n/ServiceConfig.vue'),
    meta: {
      pageTitle: 'Service Config',
      service: 'root',
      resource: 'Root-services',
      action: 'read',
      breadcrumb: [
        {
          text: 'Services',
          to: '/root/services',
        },
        {
          text: 'Config',
          active: true,
        },
      ],
      navActiveLink: 'root-services',
    },
  },
  {
    path: '/root/service/packages',
    name: 'root-service-packages',
    component: () => import('../views/root/servicePackages/ServicePackages.vue'),
    meta: {
      pageTitle: 'Service Package',
      service: 'root',
      resource: 'Root-service-packages',
      action: 'read',
    },
  },
  {
    path: '/root/application',
    name: 'root-application',
    component: () => import('../views/root/application/Application.vue'),
    meta: {
      pageTitle: 'Application',
      service: 'root',
      resource: 'Root-application',
      action: 'read',
    },
  },
  {
    path: '/root/application/manage/:id',
    name: 'root-application-manage',
    component: () => import('../views/root/application/manage/ManageApplications.vue'),
    meta: {
      pageTitle: 'Application Manage',
      service: 'root',
      resource: 'Root-application-manage',
      action: 'read',
      breadcrumb: [
        {
          text: 'Application',
          to: '/root/application',
        },
        {
          text: 'Manage',
          active: true,
        },
      ],
      navActiveLink: 'root-application',
    },
  },
  {
    path: '/root/country',
    name: 'root-country',
    component: () => import('../views/root/country/Country.vue'),
    meta: {
      pageTitle: 'Country',
      service: 'root',
      resource: 'Root-country',
      action: 'read',

    },
  },
  {
    path: '/root/geography',
    name: 'root-geography',
    component: () => import('../views/root/geography/Geography.vue'),
    meta: {
      pageTitle: 'Geography',
      service: 'root',
      resource: 'Root-geography',
      action: 'read',

    },
  },
  {
    path: '/root/bank',
    name: 'root-bank',
    component: () => import('../views/root/bank/Bank.vue'),
    meta: {
      pageTitle: 'Bank',
      service: 'root',
      resource: 'Root-bank',
      action: 'read',
    },
  },
  {
    path: '/root/parcelDelivery',
    name: 'root-parcelDelivery',
    component: () => import('../views/root/parcelDelivery/ParcelDelivery.vue'),
    meta: {
      pageTitle: 'Parcel Delivery',
      service: 'root',
      resource: 'Root-parcelDelivery',
      action: 'read',
    },
  },
  {
    path: '/root/webview/whitelist',
    name: 'root-webview-whitelist',
    component: () => import('../views/root/webviewWhitelist/WebviewWhitelist.vue'),
    meta: {
      pageTitle: 'Webview Whitelist',
      service: 'root',
      resource: 'Root-webview-whitelist',
      action: 'read',
    },
  },
  {
    path: '/root/system',
    name: 'root-system',
    component: () => import('../views/root/system/System.vue'),
    meta: {
      pageTitle: 'System',
      service: 'root',
      resource: 'Root-system',
      action: 'read',

    },
  },
]
