import root from '../../router/root'
import franchise from '../../router/franchise'
import company from '../../router/company'
import eCommerce from '../../router/services/eCommerce'

import rootNav from '../../navigation/root'
import franchiseNav from '../../navigation/franchise'
import companyNav from '../../navigation/company'
import eCommerceNav from '../../navigation/services/eCommerce'

export const rootAbility = () => {
  const rootResource = []
  const rootArray = []
  for (let index = 0; index < root.length; index += 1) {
    if (!rootResource.includes(root[index].meta.resource)) {
      rootResource.push(root[index].meta.resource)
      for (let indexArray = 0; indexArray < 4; indexArray += 1) {
        // eslint-disable-next-line no-nested-ternary
        rootArray.push({ subject: root[index].meta.resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
      }
    }
  }
  for (let index = 0; index < rootNav.length; index += 1) {
    if (!rootResource.includes(rootNav[index].resource)) {
      if (Array.isArray(rootNav[index].children)) {
        for (let indexResource = 0; indexResource < rootNav[index].children.length; indexResource += 1) {
          rootResource.push(rootNav[index].children[indexResource].resource)
        }
      }
      rootResource.push(rootNav[index].resource)
      for (let indexArray = 0; indexArray < 4; indexArray += 1) {
        // eslint-disable-next-line no-nested-ternary
        rootArray.push({ subject: rootNav[index].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
      }
      if (Array.isArray(rootNav[index].children)) {
        for (let indexResource = 0; indexResource < rootNav[index].children.length; indexResource += 1) {
          for (let indexArray = 0; indexArray < 4; indexArray += 1) {
            // eslint-disable-next-line no-nested-ternary
            rootArray.push({ subject: rootNav[index].children[indexResource].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
          }
        }
      }
    }
  }
  return rootArray
}

export const franchiseAbility = () => {
  const franchiseResource = []
  const franchiseArray = []
  for (let index = 0; index < franchise.length; index += 1) {
    if (franchise[index].meta.resource !== 'Auth') {
      if (!franchiseResource.includes(franchise[index].meta.resource)) {
        franchiseResource.push(franchise[index].meta.resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          franchiseArray.push({ subject: franchise[index].meta.resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
      }
    }
  }
  for (let index = 0; index < franchiseNav.length; index += 1) {
    if (franchiseNav[index].resource !== 'Auth') {
      if (!franchiseResource.includes(franchiseNav[index].resource)) {
        if (Array.isArray(franchiseNav[index].children)) {
          for (let indexResource = 0; indexResource < franchiseNav[index].children.length; indexResource += 1) {
            franchiseResource.push(franchiseNav[index].children[indexResource].resource)
          }
        }
        franchiseResource.push(franchiseNav[index].resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          franchiseArray.push({ subject: franchiseNav[index].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
        if (Array.isArray(franchiseNav[index].children)) {
          for (let indexResource = 0; indexResource < franchiseNav[index].children.length; indexResource += 1) {
            for (let indexArray = 0; indexArray < 4; indexArray += 1) {
              // eslint-disable-next-line no-nested-ternary
              franchiseArray.push({ subject: franchiseNav[index].children[indexResource].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
            }
          }
        }
      }
    }
  }
  return franchiseArray
}

export const companyAbility = () => {
  const companyResource = []
  const companyArray = []
  for (let index = 0; index < company.length; index += 1) {
    if (company[index].meta.resource !== 'Auth') {
      if (!companyResource.includes(company[index].meta.resource)) {
        companyResource.push(company[index].meta.resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          companyArray.push({ subject: company[index].meta.resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
      }
    }
  }
  for (let index = 0; index < companyNav.length; index += 1) {
    if (companyNav[index].resource !== 'Auth') {
      if (!companyResource.includes(companyNav[index].resource)) {
        if (Array.isArray(companyNav[index].children)) {
          for (let indexResource = 0; indexResource < companyNav[index].children.length; indexResource += 1) {
            companyResource.push(companyNav[index].children[indexResource].resource)
          }
        }
        companyResource.push(companyNav[index].resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          companyArray.push({ subject: companyNav[index].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
        if (Array.isArray(companyNav[index].children)) {
          for (let indexResource = 0; indexResource < companyNav[index].children.length; indexResource += 1) {
            for (let indexArray = 0; indexArray < 4; indexArray += 1) {
              // eslint-disable-next-line no-nested-ternary
              companyResource.push({ subject: companyNav[index].children[indexResource].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
            }
          }
        }
      }
    }
  }
  return companyArray
}

export const eCommerceAbility = () => {
  const eCommerceResource = []
  const eCommerceArray = []
  for (let index = 0; index < eCommerce.length; index += 1) {
    if (!eCommerceResource.includes(eCommerce[index].meta.resource)) {
      eCommerceResource.push(eCommerce[index].meta.resource)
      for (let indexArray = 0; indexArray < 4; indexArray += 1) {
        // eslint-disable-next-line no-nested-ternary
        eCommerceArray.push({ subject: eCommerce[index].meta.resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
      }
    }
  }
  for (let index = 0; index < eCommerceNav.length; index += 1) {
    if (!eCommerceResource.includes(eCommerceNav[index].resource)) {
      if (Array.isArray(eCommerceNav[index].children)) {
        for (let indexResource = 0; indexResource < eCommerceNav[index].children.length; indexResource += 1) {
          eCommerceResource.push(eCommerceNav[index].children[indexResource].resource)
        }
      }
      eCommerceResource.push(eCommerceNav[index].resource)
      for (let indexArray = 0; indexArray < 4; indexArray += 1) {
        // eslint-disable-next-line no-nested-ternary
        eCommerceArray.push({ subject: eCommerceNav[index].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
      }
      if (Array.isArray(eCommerceNav[index].children)) {
        for (let indexResource = 0; indexResource < eCommerceNav[index].children.length; indexResource += 1) {
          for (let indexArray = 0; indexArray < 4; indexArray += 1) {
            // eslint-disable-next-line no-nested-ternary
            eCommerceArray.push({ subject: eCommerceNav[index].children[indexResource].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
          }
        }
      }
    }
  }
  return eCommerceArray
}

export const initialAbility = [
  // {
  //   action: 'read', // 'create' | 'read' | 'update' | 'delete';
  //   subject: 'Franchise-company',
  // },
]

export const _ = undefined
