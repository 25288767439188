import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDateTime', value => {
  if (value) {
    return moment(String(value)).format('HH:mm DD-MM-YYYY')
  }
  return Vue.prototype.$stateTranslate.t('None')
})
Vue.filter('formatDateTimeNoSec', value => moment(String(value)).format('HH:mm DD-MM-YYYY'))

Vue.filter('formatTime', value => {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
  return Vue.prototype.$stateTranslate.t('None')
})

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
  return Vue.prototype.$stateTranslate.t('None')
})

Vue.filter('firstStringUpper', value => {
  const text = value.substr(0, 1)
  const word = value.slice(1)
  return Vue.prototype.$stateTranslate.t(text.toUpperCase() + word) // Vue.prototype.$stateTranslate มาจากไฟล์ main.js
})

Vue.filter('numberWithCommas', value => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})